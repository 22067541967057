import React, { useState, useEffect } from 'react';
import './HomepageEn.scss';
import '../../resources/main.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import FAQSection from "./FaqSection";
import faqs from './faqs.json'
import Slider from "react-slick";
import { NextArrow, PrevArrow } from './CustomArrow';
import { NextCadranArrow, PrevCadranArrow } from "./CadranArrow";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import mentors from './mentors.json'
import { Modal, Button } from 'antd';
import { animateScroll as scroll, scroller } from 'react-scroll';
import Header from "../../components/Header/Header";
import ImpactForm from "../../components/ImpactForm/ImpactForm";
import { formService } from "../../services/FormService";
import cadrans from './cadrans.json'
const HomepageEn = () => {
   const { search } = useLocation();
   const [isAltDomeniuChecked, setIsAltDomeniuChecked] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showFormModal = () => {
      setIsModalOpen(true);
   };
   const closeModal = () => {
      setIsModalOpen(false);
   };
   useEffect(() => {
      setTimeout(() => {
         window.dispatchEvent(new Event('resize'));
      }, 100);
   }, []);
   var settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeable: true,
      dots: false,
      cssEase: 'linear',
      adaptiveHeight: false,
      centerPadding: '0',
      swipe: true,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 1,
               centerMode: false,
            }
         }
      ]
   };
   var cadransSettings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeable: true,
      dots: true,
      cssEase: 'linear',
      adaptiveHeight: false,
      swipe: true,
      arrows: true,
      nextArrow: <NextCadranArrow />,
      prevArrow: <PrevCadranArrow />,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 3,
            }
         },
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 2,
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 1,
            }
         }
      ]
   };
   var partnerSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeable: true,
      centerMode: true,
      dots: false,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: '0',
      swipe: true,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
   };
   const [formData, setFormData] = useState({
      name: '',
      surname: '',
      email: '',
      phone: '',
      institution: '',
      city: '',
      interestDomain: [],
      gdprAgreement: false,
      newsletterSubscription: false,
      otherDomain: '',
      message: ''
   });
   const [mentorsData, setMentors] = useState(mentors)
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [selectedMentor, setSelectedMentor] = useState(null);
   const [submitting, setSubmitting] = useState(false);

   const currentLocation = useLocation();
   useEffect(() => {
      if (currentLocation.state?.scrollTo) {
         scroller.scrollTo(currentLocation.state.scrollTo, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
         });
      }
   }, [currentLocation.state]);

   const mentorsWithClones = [
      ...mentorsData,
      ...mentorsData.slice(0, 3), // Cloning first 3 mentors for smooth transition
   ];
   const showModal = (mentor) => {
      setSelectedMentor(mentor);
      setIsModalVisible(true);
   };

   const handleCancel = () => {
      setIsModalVisible(false);
      setSelectedMentor(null);
   };

   const handleChange = (e) => {
      const { name, value, type, checked } = e.target;

      if (type === 'checkbox' && name === 'interestDomain') {
         const newOptions = checked
            ? [...formData.interestDomain, value]
            : formData.interestDomain.filter(option => option !== value);

         setFormData((prevData) => ({
            ...prevData,
            interestDomain: newOptions,
         }));
      } else {
         setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
         }));
      }
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);

      formService.generalForm(formData).then(response => {
         setSubmitting(false);
         toast.success(`Cererea ta a fost trimisa cu succes.`);
      })
         .catch(error => {
            console.log(error);
            setSubmitting(false);
            toast.error('A apărut o problemă, vă rugăm să încercați din nou.');
         });
   };
   useEffect(() => {
      const params = new URLSearchParams(search);
      const scrollToId = params.get('scrollTo');
      if (scrollToId) {
         const element = document.getElementById(scrollToId);
         if (element) {
            window.scrollTo({
               top: element.offsetTop,
               behavior: 'smooth',
            });
         }
      }
   }, [search]);
   const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
   };
   return (
      <div className='standard hp form'>
         <div className='hp__header'>
            <img src='/images/logo.png' alt='logo' className='hp__logo' />
            <MobileMenu />
            {/* <Header /> */}
         </div>
         <Link to='/' className='standard__link'>
            <div className='hp__back'>
               <img src='/images/back.png' />
               <h4>Back to original homepage</h4>
            </div>
         </Link>

         <div className='hp__banner'>
            <div className='hp__banner-info'>
               <h2 className='hp__title'>Applied Education for Youth</h2>
               <h3 className='hp__subtitle'>We build bridges to the future by offering hands-on experiences and
                  applied educational programs for young people.</h3>
               <ScrollLink to='programs' className='standard__link' smooth={true} duration={500}>
                  <button className='hp__banner-button'>
                     See the programs
                  </button>
               </ScrollLink>
            </div>


            <div className='hp__benefits benefits'>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Practical Skills</h3>
                  <h4>Guided by specialists from various fields.</h4>
               </div>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Meetings with Entrepreneurs</h3>
                  <h4>CEOs and top management from leading companies.</h4>
               </div>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Educational Resources</h3>
                  <h4>Free access to educational resources for 1 year.</h4>
               </div>
            </div>
         </div>

         <div className='hp__about' id='despre'>
            <h2 className='hp__section-title'>About Edu<span>UP</span></h2>
            <p className='hp__section-description'>EduUP Association offers a new perspective: an extracurricular context
               designed to develop young people’s skills and competencies, so they can
               face the changing job market and navigate toward success. We create an
               educational environment where youth are encouraged to explore their
               potential, innovate, and collaborate.</p>
         </div>

         <div className='hp__meaning' id='ce-inseamna'>
            <h2 className='hp__section-title hp__meaning-title'>What Edu<span>UP</span> means</h2>
            <div className='hp__meaning-wrapper'>
               <div className='hp__meaning-box'>
                  <h4>Mission</h4>
                  <p>We focus on improving practical skills and teamwork, cultivating critical
                     thinking, creativity, and adaptability—key abilities in a dynamic and ever-
                     changing world.</p>
                  <img src='/images/meaning1.png' />
               </div>
               <div className='hp__meaning-box'>
                  <h4>Vision</h4>
                  <p>We create a community of young people trained by real-world
                     practitioners who inspire them and provide hands-on experiences.</p>
                  <img src='/images/meaning2.png' />
               </div>
               <div className='hp__meaning-box'>
                  <h4>Values</h4>
                  <p>Innovation, inclusion, collaboration: we promote fresh approaches, respect
                     diversity, and build teams with shared goals to reach their full potential.</p>
                  <img src='/images/meaning3.png' />
               </div>
            </div>

         </div>
         {/*
         <div className='hp__meaning'>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Skill<span>UP</span></h2>
               <Link to='/' className='standard__link'><p>Înscrieri deschise</p></Link>
            </div>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Edu<span className='yellow-span'>HUB</span></h2>
               <p>DISPONIBIL CURÂND</p>
            </div>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Masterclass</h2>
               <p>DISPONIBIL CURÂND</p>
            </div>
         </div> */}

         <div className='hp__program-section'>
            <h2 className='hp__section-title'>About the Skill<span>UP</span> program</h2>
            <p className='hp__section-description hp__about-description'>While designing this program, we formed a working group made up of student representatives from Bucharest, along with other civically engaged students. Together, we discussed the challenges they face in the current educational system.</p>
            <p className='hp__section-description hp__about-description'>The <b>SkillUP Program </b>aims to meet high school students' need for
               practical education provided by specialists, the development of relevant
               skills and competencies, and connections with mentors.</p>
            <h3 className='hp__program-title'>Challenges Faced by the Young Generation</h3>
            <div className='hp__program-wrapper'>
               <div className='hp__program-box'>
                  <h2>01</h2>
                  <h3>Lack of Practical Education</h3>
                  <p>Teenagers absorb theoretical content, but it's often not accompanied by
                     practical activities.</p>
               </div>
               <div className='hp__program-box yellow yello-cadran'>
                  <h2>02</h2>
                  <h3>Low Skill Development</h3>
                  <p>The education system doesn’t focus on the skills required by the labor
                     market.</p>
               </div>
               <div className='hp__program-box purple'>
                  <h2>03</h2>
                  <h3>Limited Access to Experts</h3>
                  <p>In addition to teachers, students need opportunities to connect with
                     mentors and industry professionals for practical insights.</p>
               </div>
            </div>
            <div className="hp__extra-info">
               <h3>SkillUP Programs</h3>
               <p className='hp__section-description'>Specially designed for young people, these training programs in your area
                  of interest offer real interactions with mentors and industry professionals,
                  hands-on experiences, and genuine insights from various fields.</p>
            </div>

         </div>


         <div className='hp__skillup-programs' id='programs'>
            <Slider  {...cadransSettings} className='hp__slider '>
               {cadrans.map(cadran => (
                  <div className='hp__single-program'>
                     <img src={cadran.imgUrl} alt='programs' className='hp__cadran-picture' />
                     <div className='hp__program-title hp__glovo-boc'>
                        <h3>{cadran.title}</h3>
                        {cadran.logo ? (
                           <img
                              className='hp__cadran-logo'
                              src={cadran.logo}
                              alt='logo'
                           />
                        ) : null}
                     </div>
                     <p>{cadran.text1}</p>
                     <p>{cadran.text2}</p>
                     <Link to={cadran.programLink} className='standard__link' onClick={scrollToTop}> <button><h4>Vezi Programul</h4></button></Link>
                  </div>
               ))}

            </Slider>

         </div>

         <div className='hp__resources'>
            <div className='hp__resources-text'>
               <h3><span className='hp__purple'>Valuable educational resources, all in one place!</span></h3>
            </div>
            <button>Comming soon</button>
         </div>

         {/* <div className='hp__pros-section' id='skillup'>
            <div className='hp__skill-title'>
               <h2 className='hp__section-title '>Skill<span>UP</span>& Business</h2>
               <ScrollLink to='chestionar' className='standard__link standard__only-desktop' smooth={true} duration={500}>

                  <button className='hp__banner-button'>Aplică acum</button>
               </ScrollLink>
            </div>

            <div className='hp__skill-right'>
               <p className='hp__section-description'> Experiențe practice oferite de către profesioniști</p>
               <p className='hp__section-description'><b>Target: </b> Elevi de liceu</p>
               <p className='hp__section-description'><b>Grup: </b>  20 liceeni </p>
               <p className='hp__section-description'><b>Durată: </b> 5-10 zile</p>
               <Link to='/' className='standard__link standard__only-mobile'>
                  <button className='hp__banner-button apply-button'>Aplică acum</button>
               </Link>
               <div className="hp__pros-container">
                  <div className='hp__pro hp__pro-title standard__only-mobile'>
                     <h3>Beneficii</h3>

                  </div>
                  <div className="hp__pro hp__pro-yellow">
                     <h4>Business training cu practicieni</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>Întâlniri cu antrepernori și experți în business</h4>
                  </div>
                  <div className="hp__pro hp__pro-red">
                     <h4>CV structurat cu expertiza unui specialist HR</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>Abilități practice, mentalitate de antreprenor</h4>
                  </div>
                  <div className="hp__pro hp__pro-red">
                     <h4>Înscriere în grup Alumni cu acces gratuit la resurse educaționale</h4>
                  </div>
                  <div className='hp__pro hp__pro-title standard__only-desktop'>
                     <h3>Beneficii</h3>

                  </div>
                  <div className="hp__pro hp__pro-yellow">
                     <h4>Participare la evenimente de business</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>la programe de voluntariat consistente și joburi sezoniere</h4>
                  </div>
                  <div className="hp__pro hp__pro-yellow">
                     <h4>Suport și ghidaj oferite de specialiști</h4>
                  </div>
                  <div className="hp__pro hp__pro-blue">
                     <h4>Premii puse la dispoziție de parteneri</h4>
                  </div>
                  <div className="hp__pro hp__pro-red">
                     <h4>O zi cu un CEO sau top management</h4>
                  </div>
               </div>

      </div>
         </div > */}
         {/* <div className='hp__programs-mentors'>
            <div className='hp__programs'>
               <img src='/images/chenar-photo.jpg' alt='photo' className='hp__programs-banner' />
               <div className='hp__programs-title'>
                  <h3>Skill<span>UP</span> & Business by</h3>
                  <img src='/images/asus-logo.png' className='hp__asus-logo' />

               </div>
               <h3 className='hp__programs-info'>Cohorta numărul #1 își deschide porțile pentru a primi 30 de liceeni pentru o experiență practică în domeniul business-ului. </h3>
               <h3 className='hp__programs-info'>Programul nostru autentic îți oferă oportunitatea de a lucra alături de profesioniști și de a primi îndrumare din partea mentorilor, experți în domeniul business-ului.</h3>
               <h3 className='hp__programs-info'>SkillUP & Business va începe la finalul lunii octombrie, hai și tu să faci parte din program
               </h3>
               <div className="hp__apply-button">
                  <button className='hp__banner-button'>       <a href='/documents/program.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                     Vezi programul</a></button>
               </div>
            </div>

            <img src='/images/MENTORII.jpg' alt='mentori' className='hp__mentors-img' />
         </div> */}

         <div className='hp__mentors-section' id='mentori'>
            <h2 className='hp__section-title hp__mentors-title'>The Skill<span>UP</span> mentors </h2>
            <Slider  {...settings} className='hp__slider '>
               {mentorsData.map(mentor => (
                  <div className='hp__mentor' key={mentor.key}>
                     <img src={mentor.imageUrl} alt='mentor' />
                     <h4>{mentor.name}</h4>
                     <h5>{mentor.function}</h5>
                     <h5>{mentor.brand}</h5>
                     <h5><i>{mentor.tags}</i></h5>
                     {mentor.bio && (
                        <h5 type='primary' onClick={() => showModal(mentor)} className='hp__see-bio'>
                           Află mai multe
                        </h5>
                     )}                  </div>
               ))}

            </Slider>

         </div>

         <div className='hp__partners-section' id='parteneri'>
            <h2 className='hp__section-title'>Partners</h2>
            <p className='hp__section-description'>Our mission is to enroll as many high school students as possible in our
               practical education programs. For that, we need long-term partners to
               support us. Thank you to everyone who trusts EduUP.</p>
            <Slider  {...partnerSettings} className='hp__slider standard__only-mobile hp__partner-slider'>
               <img src='/images/asus-logo.png' alt='partener' />
               <img src='/images/logo-artesana.jpg' alt='partner' />
               <img src='/images/partner1.png' alt='partner' />
               <img src='/images/partner2.png' alt='partner' />
               <img src='/images/animawings.jpg' alt='partner' className='hp__animawings' />


            </Slider>
            <div className='hp__partners standard__only-desktop'>
               <img src='/images/asus-logo.png' alt='partener' />
               <img src='/images/logo-artesana.jpg' alt='partner' className='hp__artesana' />
               <img src='/images/partner1.png' alt='partner' />
               <img src='/images/partner2.png' alt='partner' />
               <img src='/images/animawings.jpg' alt='partner' className='hp__animawings' />


            </div>
         </div>

         <div className='hp__cost' id='impact'>
            <h3 className='hp__cost-title'>Community-Backed Investment </h3>
            <h3 className='hp__cost-title yellow-text'> in Education</h3>
            <p>The cost of participating in the SkillUP programs is 499 lei, an amount that
               covers only the logistical costs of running the program. Our mentors are dedicated professionals who volunteer their time and
               expertise to support young people—the next generation of leaders.</p>
            <p>We believe that access to such opportunities should not be limited by
               financial resources. That’s why we offer partial and full scholarships to
               young people who show passion and determination and need support to
               participate.</p>
         </div>

         <div className='hp__meaning hp__meaning-impact' >
            <h2 className='hp__section-title hp__meaning-title'>Skill<span>UP</span> Scholarships</h2>
            <div className='hp__impact-left'>
               <p className='hp__section-description'>To ensure all teens have equal chances to participate in <b>EduUP programs</b>, we offer <spam className='yellow-span'>SCHOLARSHIPS</spam> for every cohort.</p>
               <h4 className='hp__impact-title'>Eligibility for free participation:</h4>
               <ul className="hp__impact-list">
                  <li>An<b>essay </b>  of up to 700 words on the topic: "What is the relationship
                     between young people’s skills and their ability to excel in their
                     chosen field?" </li>
                  <li>A <b>CV</b>  including extracurricular experience </li>
                  <li>An <b>interview </b> with the EduUP team</li>

               </ul>
               <p className='hp__section-description'>Thanks to our project partners, we’re able to offer free spots in each field.
                  We encourage you to apply for the scholarship program and share your
                  motivation with us!</p>
               <button className='hp__banner-button bursa-button' onClick={showFormModal}>Apply now!</button>
            </div>
            <img src="/images/impact.png" className='standard__only-desktop' />
         </div>



         <div className='hp__faq-section' id="intrebari-frecvente">
            <h2 className='hp__section-title'>FAQ</h2>
            <div className='hp__faq-container'>
               <div className='hp__faq-left'>
                  <FAQSection faqs={faqs} />
               </div>
               <div className='hp__faq-right'>
                  <img src='/images/q1.png' />
                  <img src='/images/q2.png' />

               </div>
            </div>
         </div>

         <div className='hp__form-section' id='contact'>
            <img src='/images/form-img.png' className='hp__form-img' />
            <div className='hp__form-right'>
               <h3 className='hp__form-subtitle'>Contact Us</h3>
               <form onSubmit={handleSubmit} className='hp__form' novalidate>
                  <div className='hp__form-box'>
                     <input
                        type="text"
                        name="name"
                        required
                        placeholder="Name *"
                        value={formData.name}
                        onChange={handleChange}
                     />
                     <input
                        type="text"
                        name="surname"
                        required
                        placeholder="Surname *"
                        value={formData.surname}
                        onChange={handleChange}
                     />
                  </div>
                  <div className='hp__form-box'>
                     <input
                        type="email"
                        required
                        name="email"
                        placeholder="Email *"
                        value={formData.email}
                        onChange={handleChange}
                     />
                     <input
                        type="number"
                        required
                        name="phone"
                        placeholder="Phone *"
                        value={formData.phone}
                        onChange={handleChange}
                     />
                  </div>

                  <textarea
                     type="text"
                     rows='5'
                     cols='5'
                     required
                     name="message"
                     placeholder="Message *"
                     value={formData.message}
                     onChange={handleChange}
                  />

                  <div className='hp__checkbox'>
                     <input
                        type="checkbox"
                        name="gdprAgreement"
                        checked={formData.gdprAgreement}
                        onChange={handleChange}
                     />
                     <h5>I agree with the GDPR terms for personal data processing.</h5>
                  </div>
                  <div className='hp__checkbox'>
                     <input
                        type="checkbox"
                        name="newsletterSubscription"
                        checked={formData.newsletterSubscription}
                        onChange={handleChange}
                     />
                     <h5> I agree to subscribe to the EduUP newsletter.</h5>
                  </div>
                  <button type="submit" className={`hp__form-submit ${(submitting || !formData.newsletterSubscription || !formData.gdprAgreement) ? 'disabled' : ''}`}
                     disabled={submitting || !formData.newsletterSubscription || !formData.gdprAgreement}>Sign up!</button>
               </form>
            </div>

         </div>



         {/* <div className="marquee">
            <div className="marquee__inner">
               {mentorsData.map((mentor, index) => (
                  <div className="hp__mentor" key={mentor.key || index}>
                     <img src={mentor.imageUrl} alt='mentor' />
                     <h4>{mentor.name}</h4>
                     <h5>{mentor.function}</h5>
                     <h5>{mentor.brand}</h5>
                     <h5><i>{mentor.tags}</i></h5>
                     {mentor.bio && (
                        <h5 type='primary' onClick={() => showModal(mentor)} className='hp__see-bio'>
                           Află mai multe
                        </h5>
                     )}
                  </div>
               ))}

            </div>
         </div> */}




         <div className='hp__footer'>
            <div className='hp__footer-column'>
               <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
               <div className='hp__footer-social'>
                  <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                     <img src='/images/facebook.png' alt='facebook' />
                  </a>
                  <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                     <img src='/images/instagram.png' alt='instagram' />
                  </a>
                  <a href="https://www.linkedin.com/company/asociatia-eduup/" target="_blank" className='standard__link'>
                     <img src='/images/linkedin.png' alt='linkedin' />
                  </a>
                  <a href="https://www.youtube.com/@AsociatiaEduUP" target="_blank" className='standard__link'>
                     <img src='/images/youtube.png' alt='youtube' />
                  </a>

               </div>
            </div>

            <div className='hp__footer-column'>
               <h4>EduUp</h4>
               <a href='/documents/regulament-oficial-concurs.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                  <h5>Regulament concurs Summer Well 2024
                  </h5>
               </a>
               <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                  <h5>Despre EduUp</h5>
               </ScrollLink>
               <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                  <h5>Ce înseamnă EduUp</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <h4>SkillUp</h4>
               <ScrollLink to='skillup' className='standard__link' smooth={true} duration={500}>
                  <h5>Despre SkillUp</h5>
               </ScrollLink>
               <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                  <h5>SkillUp & Business</h5>
               </ScrollLink>
               <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                  <h5>Mentori</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                  <h5>Impact Social</h5>
               </ScrollLink>
               <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                  <h5>Parteneri</h5>
               </ScrollLink>
               <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                  <h5>Întrebări frecvente</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <h4>Contact</h4>
               <h5>hello@eduup.ro</h5>
               <a className='standard__link' href="tel:+40722280027">
                  <h5>+40722280027</h5>
               </a>
               <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                  <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
               </a>
            </div>

            <Modal
               title={selectedMentor ? selectedMentor.name : ''}
               visible={isModalVisible}
               onCancel={handleCancel}
               footer={[
                  <Button key="close" onClick={handleCancel}>
                     Închide
                  </Button>,
               ]}
            >
               {selectedMentor && (
                  <>
                     <p className='hp__mentor-bio'>{selectedMentor.bio}</p>
                     <a href={selectedMentor.linkedinUrl} target="_blank" rel="noopener noreferrer">
                        Vezi profilul LinkedIn
                     </a>
                  </>
               )}
            </Modal>
            {isModalOpen && <ImpactForm isOpen={isModalOpen} onClose={closeModal} />}

         </div>
      </div >
   )
}
export default HomepageEn
