import React, { useState } from 'react';
import './Header.scss';
import '../../resources/main.scss';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
    const [isSkillUpOpen, setSkillUpOpen] = useState(false);
    const navigate = useNavigate();

    const scrollToElementOnHome = (sectionId) => {
        if (window.location.pathname !== '/') {
            navigate('/', { state: { scrollTo: sectionId } });
        } else {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleSkillUpMenu = () => {
        setSkillUpOpen(!isSkillUpOpen);
    };

    return (
        <div className='standard header'>
            <h4 className='header__item' onClick={() => scrollToElementOnHome('despre')}>Despre</h4>
            <div onClick={toggleSkillUpMenu} className='header__item-container'>
                <h4 className='header__item'>Programele SkillUP</h4>
                <span>
                    {isSkillUpOpen ? (
                        <img className='header__menu-icon' src='/images/minus.svg' alt='Collapse' />
                    ) : (
                        <img className='header__menu-icon' src='/images/plus.svg' alt='Expand' />
                    )}
                </span>
                {isSkillUpOpen && (
                    <div className='header__submenu'>
                        <Link to='/program-skillup-business' className='standard__link' onClick={scrollToTop}>
                            <h5>Business</h5>
                        </Link>
                        <Link to='/program-skillup-law' className='standard__link' onClick={scrollToTop}>
                            <h5>Law</h5>
                        </Link>
                        <Link to='/program-journalism' className='standard__link' onClick={scrollToTop}>
                            <h5>Journalism</h5>
                        </Link>
                        <Link to='/program-fashion' className='standard__link' onClick={scrollToTop}>
                            <h5>Fashion Design</h5>
                        </Link>
                        <Link to='/program-digital-marketing' className='standard__link' onClick={scrollToTop}>
                            <h5>Digital Marketing</h5>
                        </Link>
                        <Link to='/program-marketing' className='standard__link' onClick={scrollToTop}>
                            <h5>Marketing</h5>
                        </Link>
                        <Link to='/program-pr-comunicare' className='standard__link' onClick={scrollToTop}>
                            <h5>PR și Comunicare</h5>
                        </Link>
                        <Link to='/program-turism' className='standard__link' onClick={scrollToTop}>
                            <h5>Tourism      </h5>
                        </Link>
                        <Link to='/program-horeca' className='standard__link' onClick={scrollToTop}>
                            <h5>HoReCa      </h5>
                        </Link>
                        <Link to='/program-graphic-design' className='standard__link' onClick={scrollToTop}>
                            <h5>Graphic Design      </h5>
                        </Link>
                        <Link to='/programe-anterioare' className='standard__link' onClick={scrollToTop}>
                            <h5>Programe anterioare</h5>
                        </Link>
                    </div>
                )}
            </div>
            <h4 className='header__item' onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h4>
            <h4 className='header__item' onClick={() => scrollToElementOnHome('mentori')}>Mentori</h4>
            <h4 className='header__item' onClick={() => scrollToElementOnHome('impact')}>Impact social</h4>
            <h4 className='header__item' onClick={() => scrollToElementOnHome('intrebari-frecvente')}>FAQ</h4>
            <h4 className='header__item' onClick={() => scrollToElementOnHome('contact')}>Contact</h4>
            <Link to='/blog' className='standard__link' onClick={scrollToTop}>
            <h4 className='header__item' >Blog</h4>
            </Link>

            <div className='header__social'>
                <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                    <img src='/images/facebook-blue.png' alt='facebook' />
                </a>
                <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                    <img src='/images/instagram-blue.png' alt='instagram' />
                </a>
                <a href="https://www.linkedin.com/company/asociatia-eduup/" target="_blank" className='standard__link'>
                    <img src='/images/linkedin-blue.png' alt='linkedin' />
                </a>
                <a href="https://www.youtube.com/@AsociatiaEduUP" target="_blank" className='standard__link'>
                    <img src='/images/youtube-blue.png' alt='youtube' />
                </a>

            </div>

        </div>
    );
};

export default Header;
