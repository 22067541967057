import React, { useState, useEffect, useRef} from 'react';
import '../Homepage/Homepage.scss';
import './Testimonials.scss'
import '../../resources/main.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import mentors from '../Homepage/mentors.json'
import Header from "../../components/Header/Header";
import businessTestimonials from './businessTestimonials.json'
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
const { Option } = Select;

const programs = [
    {
        id: 1,
        name: 'SkillUP & Business by ASUS Business',
        mentors: '/images/mentori-program1.png',
        agenda: '/documents/agenda.pdf',
        gallery: '/galerii/galerie-program1',
        testimonials: '/testimoniale/testimoniale-program1'
    }
];
const Testimonials = () => {

    const navigate = useNavigate();
    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };

    const [selectedProgram, setSelectedProgram] = useState(programs[0]);



    const [isSkillUpOpen, setSkillUpOpen] = useState(false);

    const toggleSkillUpMenu = () => {
        setSkillUpOpen(!isSkillUpOpen);
    };

    const handleChange = (value) => {
        const program = programs.find((p) => p.id === value);
        setSelectedProgram(program);
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const masonryRef = useRef(null);

    useEffect(() => {
        if (!masonryRef.current) return; // Avoid null ref errors
    
        const masonry = new Masonry(masonryRef.current, {
            itemSelector: '.testimonials__single',
            columnWidth: '.testimonials__single', // Dynamically sizes columns
            gutter: 20,
            percentPosition: true
        });
    
        imagesLoaded(masonryRef.current, () => {
            masonry.layout();
        });
    
        return () => masonry.destroy(); // Cleanup on unmount
    }, [businessTestimonials]);
    return (
        <div className='standard hp form program anterior'>
            <div className='hp__header'>
                <Link to='/' className='standard__link' onClick={scrollToTop}>
                    <img src='/images/logo.png' alt='logo' className='hp__logo' />
                </Link>
                <MobileMenu />
                <Header />
            </div>
            <div className='hp__banner program__banner anterior__banner'>
                <h2 className='program__title'>Programe anterioare/Testimoniale</h2>


            </div>
            <Link to='/programe-anterioare' className='standard__back standard__link'>
                <img src='/images/back-blue.png' alt='back'/>
                <h4>Înapoi</h4>
            </Link>
            <div className='standard__only-mobile'>


                <div className="anterior__custom-select">
                    <select onChange={(e) => handleChange(Number(e.target.value))} defaultValue="">
                        <option value="" disabled hidden>Selectează programul</option>
                        {programs.map((program) => (
                            <option key={program.id} value={program.id}>
                                {program.name}
                            </option>
                        ))}
                    </select>
                    <img src="/images/down.png" alt='down' className='anterior__down' />
                </div>
                <div className="anterior__details">
                    <h3>{selectedProgram.name}</h3>
                    <div className="testimonials__container" ref={masonryRef}>
                        {businessTestimonials.map(testimonial => (
                            <div className="testimonials__single" key={testimonial.id}>
                                <h4 className='testimonials__name'>{testimonial.name}</h4>
                                <h5 className="testimonials__grade">{testimonial.grade}</h5>
                                <p className='testimonials__text'>{testimonial.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
           
            <div className='standard__only-desktop anterior__wrapper'>
                <div className='anterior__left-section testimonials__left-section'>
                    <h3>Alege programul</h3>
                    <h4>Business by Asus </h4>
                </div>
                <div className='anterior__center-section testimonials__central-section'>
                    <h3 className='testimonials__title'>SkillUp & Business by ASUS Business/<span className='testimonials__lighter'>Testimoniale</span>  </h3>
                    <div className="testimonials__container">
                        {businessTestimonials.map(testimonial => (
                            <div className="testimonials__single" key={testimonial.id}>
                                <h4 className='testimonials__name'>{testimonial.name}</h4>
                                <h5 className="testimonials__grade">{testimonial.grade}</h5>
                                <p className='testimonials__text'>{testimonial.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
               
            </div>


            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                        <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                            <img src='/images/facebook.png' alt='facebook' />
                        </a>
                        <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                            <img src='/images/instagram.png' alt='instagram' />
                        </a>

                    </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>

                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('despre')}>Despre EduUp</h5>
                    </ScrollLink>
                    <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>

                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </ScrollLink>
                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('mentori')}>Mentori</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('impact')}>Impact Social</h5>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h5>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>Întrebări frecvente</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>asociatiaeduup@gmail.com</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                    <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                        <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
                    </a>
                </div>


            </div>
        </div >
    )
}
export default Testimonials
